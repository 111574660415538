import React  from 'react'

export default function Loading() {
   
  return (
    <div className="loading">
   <img src={require("../Components/assets/Navbar/loader.gif")}/>
  </div>
  )
}
