import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Emicalculators from '../Components/Motorcycle/Emicalculators'


export default function EmiCalculatorPage() {
  return (
    <div>
        <Navbar/>
       <Emicalculators/>
        <Footer/>
    </div>
  )
}
