import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/Motorcycle/Scrambler-motorcycle.png';
import img2 from '../assets/Motorcycle/Roadster-motorcycle.png';
import img3 from '../assets/Motorcycle/Adventure-motocycle.png';
import { FiChevronRight } from "react-icons/fi";
import { HiArrowNarrowRight, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { FiX } from "react-icons/fi";


export default function Motorcycle() {
  const [expanded, setExpanded] = useState([false, false, false]);
  const [showForm, setShowForm] = useState(false);
  const formState = {
    Name: '',
    Email: '',
    ContactNo: '',
    Model: 'Scrambler',
    Message: '',
    Mode:'ENQUIRY',
  }
  const [formData, setFormData] = useState(formState);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false); // State for indicating form submission

  const toggleContent = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleEnquiryClick = () => {
    setErrors({});
    setShowForm(true);

  };

  const handleCloseForm = () => {
    setShowForm(false);
    setSubmitted(false)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.Name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.Email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.Email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.ContactNo.trim()) {
      errors.contact_no = "Contact No is required";
    } else if (!/^\d{10}$/.test(formData.ContactNo)) {
      errors.contact_no = "Invalid Contact No. It should be a 10-digit number.";
    }

    if (!formData.Model.trim()) {
      errors.city = "Model is required";
    }
    if (!formData.Message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setSubmitting(true); // Set submitting to true when form is being submitted
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      const formEle = e.target;
      const formDatab = new FormData(formEle);
      console.log("Formdata", formDatab);
      fetch(
        "https://sdmbikes.com/Email/testmail.php",
        {
          method: "POST",
          body: formDatab
        }
      )
        .then((res) => {
          return res.text().then(text => {
            console.log("Response from server:", text);
            return text;
          });
        })
        .then((data) => {
          console.log("Data from server:", data);
          setSubmitted(true);
          setFormData(formState);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setSubmitting(false);
       
        });
    } else {
      setErrors(validationErrors);
      setSubmitting(false);
    }
  };

  return (
    <div className='motorcycle-main'>
      <Link className='link' to='/'>
        <p className='back-page mt-4'>
          Home <span><FiChevronRight /></span> Motorcycle
        </p>
      </Link>
      <section className='motorcycle'>
        <div className="container text-center">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-12 motorcycle-main-info">
              <h2>YEZDI MOTORCYCLES</h2>
              <p>
                Embracing the spirit of innovation, the Yezdi narrative is woven by a dedicated community of motorcycle enthusiasts who envisioned the limitless potential of two-wheelers and the transformative influence they could wield in people's lives. However, the Yezdi expedition is an ongoing saga. The concluding chapter is still unwritten, fueled by our unyielding thirst for innovation. We persist in dreaming, challenging conventions, and consistently pondering the question, 'What could a motorcycle be?'
              </p>
            </div>
          </div>
          <div className="row">
            {[
              { img: img1, texthead: "Scrambler", text: "A masterpiece in chrome. With more heart, more soul. Meet the all new Scrambler" },
              { img: img2, texthead: "Roadster", text: "A masterpiece in chrome. With more heart, more soul. Meet the all new Roadster" },
              { img: img3, texthead: "Adventure", text: "A masterpiece in chrome. With more heart, more soul. Meet the all new Adventure" },

            ].map((item, index) => (
              <div key={index} className="col-lg-6 motorcycle-img">
                <img src={item.img} alt={`img-${index + 1}`} className="img-fluid yezdi-img" />
                <h5>{item.texthead}</h5>
                <p className="yezdi-img-info">
                  {expanded[index] ? item.text : `${item.text.slice(0, 80)}...`}
                  {expanded[index] && <br />}
                  <span onClick={() => toggleContent(index)} className="read-more-link">
                    {expanded[index] ? " Read Less" : " Read More"} {expanded[index] ? <HiChevronUp /> : <HiChevronDown />}
                  </span>
                </p>
              
                  <button className="yezdi-details" onClick={handleEnquiryClick}>
                    Enquiry Now
                  </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Modal Form */}
      {showForm && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center ">
          <div className="modal-bg" onClick={handleCloseForm}></div>
          <div className="modal-dialog">
            <div className="modal-content">

              <div className="modal-body">

                <form className='popup-form' method='POST' onSubmit={handleSubmit}>
                  <div className="modal-header">
                    <h5 className="modal-title">Enquiry Form</h5>
                    <FiX aria-label="Close" className='btn-closed' onClick={handleCloseForm} />
                  </div>
                  {submitted ? (
                  <div className="mb-3 enquiry-message">
                    <p>Thank you for getting in touch!. <br></br>We appreciate you contacting us / SDM Bikes. One of our colleagues will get back in touch with you soon!Have a great day!</p>
                  </div>) : (
                    <>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name</label>
                    <input type="text" className="form-control" id="name" placeholder='Name' name="Name" value={formData.Name} onChange={handleChange} />
                    {errors.name && <span className="error-message">{errors.name}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Phone No</label>
                    <input type="text" className="form-control" id="contact_no" placeholder='Phone No' name="ContactNo" value={formData.ContactNo} onChange={handleChange} />
                    {errors.contact_no && <span className="error-message">{errors.contact_no}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" className="form-control" id="email" placeholder='Email Address' name="Email" value={formData.Email} onChange={handleChange} />
                    {errors.email && <span className="error-message">{errors.email}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="selectModel" className="form-label">Select Model</label>
                    <select className="form-select" id="selectModel" name="Model" value={formData.Model} onChange={handleChange}>
                      <option value="Scrambler">Scrambler</option>
                      <option value="Roadster">Roadster</option>
                      <option value="Adventure">Adventure</option>
                    </select>
                    {errors.selectModel && <span className="error-message">{errors.selectModel}</span>}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" rows="3" placeholder='Messsage here.......' name="Message" value={formData.Message} onChange={handleChange}></textarea>
                    <input type="hidden" name="Mode" value={formData.Mode} />
                    {errors.message && <span className="error-message">{errors.message}</span>}
                  </div>
                  <div className='submit-btn'>
                    <button type="submit" className="btn btn-danger" disabled={submitting}>{submitting ? 'Submitting...' : 'Submit'}</button>

                  </div>
                  </>)}

                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
